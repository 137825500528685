@import './media.scss';

body {
	background: white;
	color: black;
	// font-family: Arial, Verdana, Tahoma, sans-serif;
	font-family: "Roboto", sans-serif;
	height: 100%;
	position: relative;
}

header {
	padding: 30px 0;
	box-shadow: 0 0px 15px -10px grey;

	.cnt-wrapper {
		display: flex;
		justify-content: space-between;

		.contact-info {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-end;

			@include sm {
				display: none;
			}

			a {
				padding: 10px 0;
				color: #44c0ff;

				.entry {
					color: #006;
				}
			}
		}
	}		
}

.nav {
	background: #000068;	

	ul {
		display: flex;	
		justify-content: center;	

		li {
			flex-grow: 1;
			display: flex;	
			justify-content: center;
			padding: 10px 0;
			line-height: 1.4;
			color: white;
			opacity: 0.7;
			transition: 100ms;
			cursor: pointer;

			&.tab-current {
				opacity: 1;
			}

			&:hover {
				opacity: 1;
			}
			span {
				h2 {
					font-size: 14px;
					text-transform: uppercase;
				}
			}
		}
	}
}


.tabs {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    font-weight: 200;
    font-size: 1em;
}

.content-wrap {
	position: relative;
	height: 80vh;

	@include md {
		height: auto;
	}

	section {
		display: none;
		margin: 0 auto;
		padding: 1em;
		max-width: 1200px;

		&.content-current {
			display: block;
		}
	}

	#section-line-1 {
		.about-company {
			h3 {
				padding: 10px 0;
				font-size: 24px;
				text-align: center;
				text-align: center;
			}
			p {
				padding: 5px 0;
				font-size: 14px;
			}
		}
	}
	#section-line-4 {
		h3 {
			padding: 10px 0;
			font-size: 24px;
			text-align: center;
			margin-bottom: 30px;
		}

		.certificates-list {
			display: flex;
			justify-content: space-between;
			.certificate {
				width: 24.5%;

			}
		}
	}
	#section-line-5 {
		padding: 30px 0;

		@include sm {
			padding: 30px 20px;
		}

		h3 {
			padding: 10px 0;
			font-size: 24px;
			text-align: center;
			margin-bottom: 15px;
			color: #000071;

			@include sm {
				font-size: 18px;
			}
		}

		center {
			font-size: 20px;
			color: #000071;
			text-transform: uppercase;

			@include sm {
				font-size: 16px;
			}
		}

		.offer-buttons {
			display: flex;
			justify-content: center;
			padding: 40px 0;

			@include md {
				flex-direction: column;
			}

			a {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-right: 20px;

				@include md {
					margin-right: 0;
					margin-bottom: 20px;
				}

				&:last-child {
					margin-right: 0;
				}

				span {
					padding: 5px 0;
				}
			}
		}
	}
	#section-line-6 {
		h3 {
			padding: 10px 0;
			font-size: 24px;
			text-align: center;
			margin-bottom: 30px;
		}

		.block {
			display: flex;
			justify-content: center;

			a {

			}
		}
	}

	#section-line-7 {
		h3 {
			padding: 10px 0;
			font-size: 24px;
			text-align: center;
			margin-bottom: 30px;
		}

		table {
			margin: 0 auto;
			tbody {
				border-top: 1px solid #ccc;

				tr {
					th {
						border: #666 solid 1px;
    					padding: 5px;
    					background-color: #eff9ff;
    					vertical-align: top;
					}

					td {
						border: #666 solid 1px;
    					padding: 5px;
    					vertical-align: top;
					}

				}
			}
		}

		.map {
			display: flex;
			justify-content: center;
		}
	}
}

footer {
	background: #efefef;
	color: black;
	padding: 20px 0;

	.cnt-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include md {
			flex-direction: column-reverse;
		}

		.cnt-left {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.developed {
				display: flex;
				align-items: center;
				padding: 15px 0;

				@include xs {
					flex-direction: column;
				}

				img {
					max-width: 140px;
				}
			}
			& > div {
				
				@include md {
					text-align: center;
				}
			}
		}
		
		.contacts {
			display: flex;
			flex-direction: column;
			align-items: flex-end;

			@include md {
				align-items: center;
			}

			a {
				padding: 10px 0;
			}
		}
		
	}
}

.form-wrapper {
	display: flex;
	width: 100%;
	font-family: "Roboto";
	padding: 30px 0;

	form {
		width: 100%;

		.fieldWrapper {
			display: flex;
			align-items: center;
			padding: 5px 0;

			@include sm {
				flex-direction: column;
			}

			label {
				margin-right: 10px;
				width: 50%;

				@include sm {
					width: 100%;
					margin-right: 0;
					margin-bottom: 5px;
				}
			}

			input, select {
				display: block;
			    width: 50%;
			    padding: .375rem .75rem;
			    font-size: 1rem;
			    line-height: 1.5;
			    color: #495057;
			    background-color: #fff;
			    background-clip: padding-box;
			    border: 1px solid #ced4da;
			    border-radius: .25rem;
			    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

			    @include sm {
					width: 100%;
				}
			}
		}

		.form-group {
			.btn {
				padding: 15px 30px;
				background: #0075c7;
				color: white;
				border: none;
				border-radius: 15px;
				font-size: 16px;
				font-weight: bold;
			}
		}
	}
}

.result-wrapper {
	display: flex;
	flex-direction: column;
	padding: 30px 0;
	min-height: calc(100vh - 160px);
	height: 100%;

	.cnt-wrapper {
		height: 100%;
		// display: flex;


		.links {
			display: flex;
			justify-content: center;
			margin-bottom: 30px;

			@include sm {
				flex-direction: column;
				align-items: center;
			}

			a {
				padding: 15px 30px;
				background: #efefef;
				border-radius: 5px;
				margin-right: 10px;
				transition: 100ms;

				@include sm {
					width: 100%;
					max-width: 320px;
					margin-right: 0;
					margin-bottom: 10px;
					text-align: center;
				}

				&:last-child {
					margin-right: 0;
				}

				&:hover {
					background: #d3d3d3;
				}

			}
		}

		.personal_offer {
			input[type="submit"] {
				padding: 15px 30px;
				background: #efefef;
				border-radius: 5px;
				margin-right: 10px;
				transition: 100ms;
				cursor: pointer;
				border: none;
				outline: none;

				@include sm {
					width: 100%;
					max-width: 320px;
					margin-right: 0;
					margin-bottom: 10px;
					text-align: center;
				}

				&:hover {
					background: #d3d3d3;
				}
			}
		}

		.message {
			padding: 50px 20px;
			font-size: 24px;
			text-align: center;

			@include md {
				font-size: 16px;
			}
		}

		.return {
			text-align: center;

			&-link {
				color: #93dfff;
				text-decoration: underline;
			}
		}
	}
}






