
html, body {
	margin: 0;
	padding: 0;
	width: 100%;
	overflow-x: hidden;
	min-height: 100%;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.container {
	width: 100%;
	height: 100%;
	max-width: 1170px;
	margin: 0 auto;
}

html {
	width: 100vw;
}

body {
	font-family: "Roboto",sans-serif;
    font-weight: 400;
    line-height: normal;
}

a {
	text-decoration: none;
	color: inherit;
}

img {
	max-width: 100%;
	max-height: 100%;
}

.show-mobile {
	display: none !important;
}

@media only screen and (min-width: 1440px) {
	// .wrapper .container {
	// 	min-height: 100vh !important;
	// }
}

@media only screen and (max-width: 1280px) {
	.container {
		max-width: 960px;
	}
}

@media only screen and (max-width: 980px) {
	.container {
		max-width: 890px;
	}
}

@media only screen and (max-width: 960px) {
	.container {
		width: 100%;
		padding: 0 20px;
	}
}

@media only screen and (max-width: 768px) {
	.container {
		max-width: 100%;
		padding: 0 20px;
	}

	.mobile-hidden {
		display: none !important;
	}

	.show-mobile {
		display: block !important;
	}
}


