$laptops: 1280px;
$tablets: 1024px;
$lg: 960px;
$md: 768px;
$sm: 576px;
$xs: 480px;

@mixin laptops {
	@media screen and (max-width: $laptops) {
		@content;
	}
}

@mixin tablets {
	@media screen and (max-width: $tablets) {
		@content;
	}
}

@mixin lg {
	@media screen and (max-width: $lg) {
		@content;
	}
}

@mixin md {
	@media screen and (max-width: $md) {
		@content;
	}
}

@mixin sm {
	@media screen and (max-width: $sm) {
		@content;
	}
}

@mixin xs {
	@media screen and (max-width: $xs) {
		@content;
	}
}